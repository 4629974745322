import(/* webpackMode: "eager" */ "/workspace/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/workspace/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/web/components/elements/toaster/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/web/components/logs.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/web/components/Rudderstack.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/web/contexts/company-provider.tsx");

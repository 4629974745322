'use client'

import decodeJWT from '@hiretalk/libraries/decode-jwt'
import { setIdentity } from '@hiretalk/libraries/trackingUtils'
import React, { createContext, useContext, useMemo } from 'react'

import { ProcessingProvider } from './processing-provider'
import { ApolloWrapper } from '@hiretalk/app/ApolloWrapper'

type Props = {
  children: React.ReactNode
  cookie?: string
}

type UserContextType = {
  email: string
  id: string
}

const UserContext = createContext<UserContextType | null>(null)

const CompanyProvider = ({ children, cookie }: Props) => {
  const user = useMemo(() => {
    if (cookie) {
      try {
        const decoded = decodeJWT(cookie)
        return { email: decoded.email, id: decoded.sub }
      } catch (error) {
        console.error('Failed to decode JWT', error)
        return null
      }
    }
    return null
  }, [cookie])
  if (user) {
    setIdentity(user)
  }
  return (
    <UserContext.Provider value={user}>
      <ApolloWrapper cookie={cookie}>
        <ProcessingProvider>{children}</ProcessingProvider>
      </ApolloWrapper>
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error('useGetUser must be used within a UserContext.Provider')
  }
  return context
}

export default CompanyProvider

'use client'
import { getAPIURL } from '@hiretalk/libraries/get-api-url'
import { type GET_PROCESSING_ITEMSQuery, type ProcessingItems } from '@hiretalk/sdk'
import { useParams } from 'next/navigation'
import { createContext, useContext, useEffect, useReducer, useRef } from 'react'
import { io } from 'socket.io-client'

const ProcessingContext = createContext<ProcessingContextType | null>(null)

const initialState: ProcessingItems = {
  candidateWaitingStatusUpdates: [],

  // Add any other necessary fields to match the 'ProcessingItems' type
}
type Props = {
  children: React.ReactNode
  cookie?: string
}
type InitialProcessingItemsAction = {
  type: 'initial'
  payload: GET_PROCESSING_ITEMSQuery
}

type ProcessingStatusUpdate = {
  type: 'update'
  status: 'processing' | 'completed' | 'added'
  candidateId: string
  campaignId: string
}

type ProcessingReducerActions = InitialProcessingItemsAction | ProcessingStatusUpdate

type ProcessingContextType = {
  processingItems: ProcessingItems
  dispatch: (action: ProcessingReducerActions) => void
}
const processingReducer = (state: ProcessingItems, action: ProcessingReducerActions) => {
  switch (action.type) {
    case 'initial':
      return { ...action.payload.getProcessingItems }
    case 'update': {
      const { candidateWaitingStatusUpdates = [] } = state
      const curArray = [...(candidateWaitingStatusUpdates ?? [])]
      switch (action.status) {
        case 'added':
        case 'processing':
          if (curArray.includes(action.candidateId)) {
            return state
          }
          return {
            ...state,
            candidateWaitingStatusUpdates: [...curArray, action.candidateId],
          }
        case 'completed':
          return {
            ...state,
            candidateWaitingStatusUpdates: curArray.filter((candidateId) => candidateId !== action.candidateId),
          }
        default:
          return state
      }
    }
    default:
      return state
  }
}

export const socket = io(getAPIURL(), {
  withCredentials: true,
  path: '/socket.io/', // Explicitly set the path if not using default
  transports: ['websocket'], // Optional: enforce WebSocket to avoid initial HTTP polling
})
export function usePrevious<T>(value: T): T | undefined {
  const ref = useRef<T>()
  useEffect(() => {
    ref.current = value
  }, [value])
  return ref.current
}

export const ProcessingProvider = ({ children }: Props) => {
  const { candidateId, campaignId } = useParams()
  const [processingItems, dispatch] = useReducer(processingReducer, initialState)
  // const { data } = useQuery(GET_PROCESSING_ITEMS, {
  //   variables: {
  //     candidateId: candidateId as string,
  //     campaignId: campaignId as string,
  //   },
  //   onCompleted(data) {
  //     dispatch({ type: 'initial', payload: data })
  //   },
  // })
  const prevCampaignId = usePrevious(campaignId)

  useEffect(() => {
    if (!campaignId || prevCampaignId === campaignId) {
      return
    }
    socket.on(`processing-${campaignId}`, (data) => {
      dispatch(data)
      // Update your UI here based on the completed campaign
    })

    return () => {
      if (prevCampaignId) {
        console.log('unsubscribing from processing updates for campaign', prevCampaignId)
        socket.off(`processing-${prevCampaignId}`)
      }
    }
  }, [campaignId])

  return <ProcessingContext.Provider value={{ processingItems, dispatch }}>{children}</ProcessingContext.Provider>
}

export const useProcessing = () => {
  const context = useContext(ProcessingContext)
  if (context === undefined) {
    throw new Error('useProcessing must be used within a ProcessingContext.Provider')
  }

  return context
}

export const useCandidateProcessing = (candidateId: string) => {
  const processingContext = useProcessing()
  if (!processingContext) {
    // Handle the null case, e.g., by returning null or throwing an error
    throw new Error('ProcessingContext is null')
  }
  const {
    processingItems: { candidateWaitingStatusUpdates },
  } = processingContext
  const allQueueItems = [candidateWaitingStatusUpdates].flat()
  return {
    processingCandidateStatus: candidateWaitingStatusUpdates?.includes(candidateId),
    processingCandidate: allQueueItems?.includes(candidateId),
  }
}

type ReturnValue = {
  iat: number
  exp: number
  sub: string
  email: string
}

const decodeJWT = (token: string): ReturnValue => {
  const parts = token.split('.')
  if (parts.length !== 3) {
    throw new Error('Invalid token format')
  }

  const payload = parts[1]
  const decodedPayload = Buffer.from(payload, 'base64').toString('utf8')
  const decodedObj = JSON.parse(decodedPayload)

  if (!decodedObj.iat || !decodedObj.exp) {
    throw new Error('Token does not contain iat and exp claims')
  }

  const iat = decodedObj.iat
  const exp = decodedObj.exp
  const sub = decodedObj.sub

  return { ...decodedObj }
}

export default decodeJWT

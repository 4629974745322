'use client'

import React from 'react'

// type Props = {
//   auth: AwaitedReturn<typeof getAuth>
// }

// eslint-disable-next-line react/display-name
const Logs = React.memo(() => {
  // const environment = process.env.NEXT_PUBLIC_ENV

  // eslint-disable-next-line no-console
  // console.log({ provider: 'hiretalk', environment, isAuthenticated: auth.isAuthenticated })

  return null
})

export default Logs

'use client'

import { Toaster as HotToaster } from 'react-hot-toast'

const Toaster = () => {
  return (
    <HotToaster
      position='bottom-right'
      toastOptions={{
        success: {
          style: {
            color: '#009628',
            border: '#009628',
            background: '#EBFFE2',
            whiteSpace: 'nowrap',
          },
        },
        loading: {
          style: {
            color: 'black',
            border: '#EBF8FF',
            background: '#EBF8FF',
            whiteSpace: 'nowrap',
          },
        },
        error: {
          style: {
            color: '#C33434',
            border: '#C33434',
            background: '#FFF5F5',
            whiteSpace: 'nowrap',
          },
        },
      }}
    />
  )
}

export default Toaster

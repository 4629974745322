'use client'
// ^ this file needs the "use client" pragma

import { ApolloLink, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import {
  ApolloNextAppProvider,
  NextSSRInMemoryCache,
  NextSSRApolloClient,
} from '@apollo/experimental-nextjs-app-support/ssr'
import { getAPIURL } from '@hiretalk/libraries/get-api-url'

const httpLink = new HttpLink({
  uri: getAPIURL() + '/graphql/company',
  credentials: 'include',
})

const asyncAuthLink = (cookie?: string) => {
  return setContext(async () => {
    return { headers: { authorization: `bearer ${cookie}` } }
  })
}

// have a function to create a client for you
function makeClient(cookie?: string) {
  return new NextSSRApolloClient({
    // use the `NextSSRInMemoryCache`, not the normal `InMemoryCache`
    cache: new NextSSRInMemoryCache(),
    link:
      typeof window === 'undefined'
        ? ApolloLink.from([asyncAuthLink(cookie), httpLink])
        : ApolloLink.from([asyncAuthLink(cookie), httpLink]),
  })
}

// you need to create a component to wrap your app in
export function ApolloWrapper({ children, cookie }: React.PropsWithChildren<{ cookie?: string }>) {
  return <ApolloNextAppProvider makeClient={() => makeClient(cookie)}>{children}</ApolloNextAppProvider>
}

declare global {
  interface Window {
    rudderanalytics?: {
      load: (writeKey: string, dataPlaneUrl: string, options?: { integrations: { All: true } }) => void
      page?: () => void
      ready?: (callback: () => void) => void
    }
  }
}
export async function rudderInitialize() {
  const rudderanalytics = await import('rudder-sdk-js')
  window.rudderanalytics = rudderanalytics.default || rudderanalytics

  window.rudderanalytics.load(
    process.env.NEXT_PUBLIC_RUDDERSTACK_WRITEKEY || '',
    process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL || '',
    {
      integrations: { All: true }, // load call options
    },
  )

  if (window.rudderanalytics?.ready) {
    window.rudderanalytics.ready(() => {})
  }
}
